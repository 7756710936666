import {
  reactive,
} from '@vue/composition-api'

export default reactive({
  currentPage: null,
  perPage: null,
  totalRows: null,
  infList: [],
  admList: [],
  isBusy: false,
  filterParams: {},
  sortBy: '-idx',
  isLoading: false,
})
