<template>
  <BAvatarGroup size="32px">
    <TagbySnsAvatarV2
      v-for="(channel, i) in channelList"
      :key="i"
      :channel="channel"
    />
  </BAvatarGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BAvatarGroup,
} from 'bootstrap-vue'
import TagbySnsAvatarV2 from '@/components/TagbySnsAvatarV2.vue'

export default defineComponent({
  components: {
    BAvatarGroup,
    TagbySnsAvatarV2,
  },
  props: {
    channelList: {
      type: Array,
    },
  },
  setup() {
  },
})
</script>
