import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: '이름',
      paramName: 'name',
    },
    {
      label: '휴대번호',
      paramName: 'phone',
    },
    {
      label: 'IDX',
      paramName: 'idx',
      format: 'number',
    },
    {
      label: '링크가입여부',
      paramName: 'is_link_user',
      format: 'checkbox',
      options: [
        { text: 'true', value: 'true' },
        { text: 'false', value: 'false' },
      ],
    },
    {
      label: '성별',
      paramName: 'gender',
      format: 'checkbox',
      options: [
        { text: 'M', value: 'M' },
        { text: 'F', value: 'F' },
      ],
    },
    {
      label: '상태',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'BAN', value: 'BAN' },
        { text: 'WITHDRAW', value: 'WITHDRAW' },
      ],
    },
    {
      label: 'SNS종류',
      paramName: 'sns_service',
      format: 'checkbox',
      options: [
        { text: 'INSTA BASIC', value: 'INSTA_BASIC' },
        { text: 'INSTA BIZ', value: 'INSTA_BIZ' },
        { text: 'NAVER BLOG', value: 'NAVER_BLOG' },
        { text: 'YOUTUBE', value: 'YOUTUBE' },
        { text: 'TIKTOK', value: 'TIKTOK' },
      ],
    },
    {
      label: 'SNS아이디',
      paramName: 'sns_username',
    },
    {
      label: '마이스토어 이름',
      paramName: 'myshop_url_path',
    },
    {
      label: '가입일시',
      paramName: 'created_at',
      format: 'date',
    },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.infList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx', sortable: true },
    { key: 'name', label: 'name' },
    { key: 'sns', label: 'sns' },
    { key: 'phone', label: 'phone' },
    { key: 'gender', label: 'gender' },
    { key: 'point', label: 'point' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
