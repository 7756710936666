<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'member-inf-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'member-inf-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(sns)="data">
        <TagbySnsAvatarGroup :channelList="data.value" />
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbySnsAvatarGroup from '@/components/TagbySnsAvatarGroup.vue'
import state from './state'
import getters from './getters'

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    TagbySnsAvatarGroup,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    }
  },
})
</script>
